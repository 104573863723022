import { create } from "zustand";
import { persist } from "zustand/middleware"; // Import the persist middleware
import { game_schema } from "./GameSchema";


function updateIntersectionAndRemove(obj1, obj2) {
    const keysInObj1 = Object.keys(obj1);
    const commonKeys = keysInObj1.filter(key => key in obj2);

    commonKeys.forEach(key => {
        obj1[key] = obj2[key];
    });

    keysInObj1.forEach(key => {
        if (!(key in obj2)) {
            delete obj1[key];
        }
    });

    console.log("gameState", obj1);
    return obj1;
}

export const gameSchema = {
    user: null,
    token: null,
    threads: [],
    gameState: {
        ...game_schema
    },
    flyTo: null,
    light: null,
    zoomScope: 'local',
    chapters: [[]],
    initialised: false,
    audioText: "",
}

export const GlobalStore = create(
    persist(
        (set, get) => ({
            ...gameSchema,
            setUser: (value) => set({ user: value }),
            setToken: (value) => set({ token: value }),
            resetToInitial: () => set({ ...gameSchema }),

            // Function to clear persisted storage and reset state
            clearPersistedState: () => {
                GlobalStore.persist.clearStorage().then(() => {
                    set({ ...gameSchema })
                })
            },

            setGameState: (value) => {
                const current = get().gameState;
                // console.log(current, value)
                if (value)
                    set({ gameState: updateIntersectionAndRemove(current, value) });
            },

            setAudioText: (value) => set({ audioText: value }),
            setInitialised: (value) => set({ initialised: value }),
            setChapters: (value) => set({ chapters: value }),
            setZoomScope: (value) => set({ zoomScope: value }),
            setThreads: (value) => set({ threads: value }),
            setLight: (value) => set({ light: value }),
            setFlyTo: (value) => {
                let o = { ...value, zoom: value.zoom - 2 || 8, center: value.center || [value.long, value.lat] };
                console.log(o);
                set({ flyTo: o });
            }
        }),
        {
            name: "global-store", // The key under which the state will be stored in localStorage
            // optionally, you can add custom logic like versioning or partializing state here
        }
    )
);

// Define a function outside to clear persisted state
export const clearPersistedState = async () => {
    await GlobalStore.persist.clearStorage();
    await GlobalStore.setState({ ...gameSchema });
};