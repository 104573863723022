import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';


const Tabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState('character');

  const tabs = [
    { id: 'character', label: 'Character' },
    { id: 'inventory', label: 'Inventory' },
    { id: 'npcs', label: 'NPCs' },
    { id: 'locations', label: 'Locations' },
    { id: 'quests', label: 'Quests' }
  ];

  return (
    <div className="w-full max-w-6xl bg-warmGray-900 p-6 rounded-lg">
      <div className="flex gap-6">
        {/* Vertical Tab Navigation */}
        <div className="w-48 shrink-0">
          <div className="flex flex-col space-y-2 bg-warmGray-800 p-2 rounded-lg">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  w-full px-4 py-3 text-left text-sm font-medium rounded-md
                  transition-all duration-200 relative
                  ${activeTab === tab.id
                    ? 'bg-teal-600 text-white'
                    : 'text-warmGray-400 hover:text-white hover:bg-warmGray-700'
                  }
                `}
              >
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="activeTab"
                    className="absolute inset-0 bg-teal-600 rounded-md z-0"
                  />
                )}
                <span className="relative z-10">{tab.label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Content Area */}
        <div className="flex-1">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.2 }}
            className="h-full"
          >
            {/* Tab content remains the same as before, just add min-h-full to container */}
            {/* ... rest of the tab content ... */}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const GameTabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState('map');

  const tabs = [
    { id: 'map', label: "Map" },
    { id: 'character', label: 'Character' },
    { id: 'inventory', label: 'Inventory' },
    { id: 'npcs', label: 'NPCs' },
    { id: 'locations', label: 'Locations' },
    { id: 'quests', label: 'Quests' }
  ];

  return (<div id="GameInterface" className={twMerge(" bg-warmGray-900 m-2 rounded-lg absolute z-10 top-[70px] bg-opacity-0 gap-2", activeTab === 'map' ? 'grid-cols-none' : 'max-w-[70vw] grid grid-cols-12')}>
    {/* Tab Navigation */}


    {/* <div className="flex space-x-1 p-1 rounded-lg mb-4">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setActiveTab(tab.id)}
          className={`
              flex-1 px-4 py-2 text-sm font-medium rounded-md transition-all duration-200
              ${activeTab === tab.id
              ? 'bg-teal-600 text-white'
              : 'text-warmGray-400 hover:text-white hover:bg-warmGray-700'}
            `}
        >
          {tab.label}
        </button>
      ))}
    </div> */}


    {/* Vertical Tab Navigation */}
    <div className="w-full shrink-0 col-span-2">
      <div className="flex flex-col space-y-2 bg-warmGray-800 p-2 rounded-lg">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`
                  w-full px-4 py-3 text-left text-sm font-medium rounded-md
                  transition-all duration-200 relative
                  ${activeTab === tab.id
                ? 'bg-teal-600 text-white'
                : 'text-warmGray-400 hover:text-white hover:bg-warmGray-700'
              }
                `}
          >
            {activeTab === tab.id && (
              <motion.div
                layoutId="activeTab"
                className="absolute inset-0 bg-teal-600 rounded-md z-0"
              />
            )}
            <span className="relative z-10">{tab.label}</span>
          </button>
        ))}
      </div>
    </div>

    {activeTab !== 'map' && <div className={"overflow-y-auto max-h-[85vh] rounded-sm col-span-10"}>
      {/* Character Tab */}
      {activeTab === 'character' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-warmGray-800 rounded-lg p-6"
        >
          <h2 className="text-xl font-bold text-teal-400 mb-4 pb-3 border-b border-warmGray-700">
            {data.player.playerTitle}
            <div className="text-sm text-orange-300 mt-1">{data.player.playerIronicTitle}</div>
          </h2>


          <div className="space-y-2">

            {/* Energy Bar */}
            <motion.div
              initial={{ x: -20 }}
              animate={{ x: 0 }}
              className="space-y-2"
            >
              <div className="flex justify-between text-sm text-warmGray-400">
                {/* <span>Level {data.player.lvl}</span> */}
                <span />
                <span>{data.player.health}/{data.player.healthMax} Health</span>
              </div>

              <div className="h-1 bg-warmGray-700 rounded-full overflow-hidden">
                <div
                  className="h-full bg-green-500 transition-all duration-300"
                  style={{ width: `${(data.player.health / data.player.healthMax) * 100}%` }}
                />
              </div>
            </motion.div>

            {/* Energy Bar */}
            <motion.div
              initial={{ x: -20 }}
              animate={{ x: 0 }}
              className="space-y-2"
            >
              <div className="flex justify-between text-sm text-warmGray-400">
                {/* <span>Level {data.player.lvl}</span> */}
                <span />
                <span>{data.player.energy}/100 Energy</span>
              </div>

              <div className="h-1 bg-warmGray-700 rounded-full overflow-hidden">
                <div
                  className="h-full bg-yellow-500 transition-all duration-300"
                  style={{ width: `${(data.player.energy / 100) * 100}%` }}
                />
              </div>
            </motion.div>


            {/* XP Bar */}
            <motion.div
              initial={{ x: -20 }}
              animate={{ x: 0 }}
              className="space-y-2"
            >
              <div className="flex justify-between text-sm text-warmGray-400">
                <span>Level {data.player.lvl}</span>
                <span>{data.player.xp}/{data.player.nextLevelXp} XP</span>
              </div>
              <div className="h-1 bg-warmGray-700 rounded-full overflow-hidden">
                <div
                  className="h-full bg-teal-500 transition-all duration-300"
                  style={{ width: `${(data.player.xp / data.player.nextLevelXp) * 100}%` }}
                />
              </div>
            </motion.div>




            {/* Stats */}
            <h3 className="text-sm font-medium text-teal-400">Stats</h3>
            {data.player.stats.map((stat, index) => (
              <motion.div
                key={stat.name}
                initial={{ x: -20 }}
                animate={{ x: 0 }}
                transition={{ delay: 0.1 * index }}
                className="bg-warmGray-700/50 p-3 rounded-lg"
              >
                <div className="flex justify-between text-sm">
                  <span className="text-white">{stat.name}</span>
                  <span className="text-teal-400">{stat.value}</span>
                </div>
                <div className="text-xs text-warmGray-400 mt-1">{stat.effect}</div>
              </motion.div>
            ))}

            {/* Buffs */}
            <h3 className="text-sm font-medium text-teal-400">Buffs</h3>
            {data.player.buffs.map((buff, index) => (
              <motion.div
                key={buff.name}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 + (0.1 * index) }}
                className="bg-teal-900/30 p-3 rounded-lg"
              >
                <div className="text-teal-300 font-medium">{buff.name}</div>
                <div className="text-warmGray-400 text-sm mt-1">{buff.effect}</div>
                <div className="text-warmGray-500 text-xs mt-1">Duration: {buff.duration}</div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}

      {/* Inventory Tab */}
      {activeTab === 'inventory' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-warmGray-800 rounded-lg p-6"
        >
          <h2 className="text-xl font-bold text-teal-400 mb-4 pb-3 border-b border-warmGray-700">
            Inventory
          </h2>

          {/* Weapon Section */}
          <div className="space-y-2">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-2"
            >
              <h3 className="text-sm font-medium text-teal-400">Equipped Weapon</h3>
              {data.player.weapon ? <div className="bg-warmGray-700 p-4 rounded-lg">
                <div className="text-white font-medium">{data.player.weapon.name}</div>
                <div className="text-xs text-orange-300 mt-1">{data.player.weapon.ironicName}</div>
                <div className="text-sm text-warmGray-400 mt-2">{data.player.weapon.effects}</div>
                <div className="flex justify-between mt-2 text-sm text-warmGray-400">
                  <span>Durability: {data.player.weapon.durability}%</span>
                  <span>Weight: {data.player.weapon.weight}kg</span>
                </div>
              </div> : ''}
            </motion.div>

            {/* Scrolls */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="space-y-2"
            >
              <h3 className="text-sm font-medium text-teal-400">Spells</h3>
              {data.player.spells?.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ x: -20 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="bg-warmGray-700 p-4 rounded-lg"
                >
                  <div className="flex justify-between">
                    <span className="text-white font-medium">{item.name}</span>
                    <span className="text-teal-400">{item.energy} Energy</span>
                  </div>
                  <div className="text-sm text-warmGray-400 mt-1">{item.description}</div>
                  <div className="text-sm text-warmGray-400 mt-2">{item.effect}</div>
                </motion.div>
              ))}
            </motion.div>


            {/* Scrolls */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="space-y-2"
            >
              <h3 className="text-sm font-medium text-teal-400">Scrolls</h3>
              {data.player.scrolls?.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ x: -20 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="bg-warmGray-700 p-4 rounded-lg"
                >
                  <div className="flex justify-between">
                    <span className="text-white font-medium">{item.name}</span>
                    <span className="text-teal-400">x{item.quantity}</span>
                  </div>
                  <div className="text-sm text-warmGray-400 mt-1">{item.description}</div>
                  <div className="text-sm text-warmGray-400 mt-2">{item.effect}</div>
                  <div className="flex justify-between mt-2 text-sm text-warmGray-400">
                    <span>{item.value} gold</span>
                    <span>{item.weight}kg</span>
                  </div>
                </motion.div>
              ))}
              {data.player.spellscrolls?.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ x: -20 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="bg-warmGray-700 p-4 rounded-lg"
                >
                  <div className="flex justify-between">
                    <span className="text-white font-medium">{item.name}</span>
                    <span className="text-teal-400">x{item.quantity}</span>
                  </div>
                  <div className="text-sm text-warmGray-400 mt-1">{item.description}</div>
                  <div className="text-sm text-warmGray-400 mt-2">{item.effect}</div>
                  <div className="flex justify-between mt-2 text-sm text-warmGray-400">
                    <span>{item.value} gold</span>
                    <span>{item.weight}kg</span>
                  </div>
                </motion.div>
              ))}
            </motion.div>

            {/* Items */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="space-y-2"
            >
              <h3 className="text-sm font-medium text-teal-400">Items</h3>
              {data.player.inventory.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ x: -20 }}
                  animate={{ x: 0 }}
                  transition={{ delay: 0.1 * index }}
                  className="bg-warmGray-700 p-4 rounded-lg"
                >
                  <div className="flex justify-between">
                    <span className="text-white font-medium">{item.name}</span>
                    <span className="text-teal-400">x{item.quantity}</span>
                  </div>
                  <div className="text-sm text-warmGray-400 mt-1">{item.description}</div>
                  <div className="text-sm text-warmGray-400 mt-2">{item.effect}</div>
                  <div className="flex justify-between mt-2 text-sm text-warmGray-400">
                    <span>{item.value} gold</span>
                    <span>{item.weight}kg</span>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </motion.div>
      )}

      {/* NPCs Tab */}
      {activeTab === 'npcs' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-warmGray-800 rounded-lg p-6"
        >
          <h2 className="text-xl font-bold text-teal-400 mb-4 pb-3 border-b border-warmGray-700">
            NPCs
          </h2>

          <div className="space-y-2">
            {data.npc.map((npc, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * index }}
                className="bg-warmGray-700 p-4 rounded-lg"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-white font-medium">{npc.name}</h3>
                    <div className="text-sm text-warmGray-400">{npc.race} • {npc.job}</div>
                  </div>
                  <span className="px-2 py-1 text-xs font-medium bg-teal-900/50 text-teal-200 rounded-md">
                    {npc.relationship}
                  </span>
                </div>

                <div className="mt-4 space-y-3">
                  <div className="text-sm text-warmGray-400">
                    <p className="italic">{npc.legendary}</p>
                  </div>
                  <div className="text-sm text-warmGray-400">
                    <span className="text-teal-400">Traits:</span> {npc.traits}
                  </div>
                  <div className="text-sm text-warmGray-400">
                    <span className="text-teal-400">Motivation:</span> {npc.motivation}
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    {npc.stats.map((stat, statIndex) => (
                      <div key={statIndex} className="bg-warmGray-800 p-2 rounded">
                        <div className="text-sm text-white">{stat.name}: {stat.value}</div>
                        <div className="text-xs text-warmGray-400">{stat.effect}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}

      {/* Locations Tab */}
      {activeTab === 'locations' && data.worldLocations && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="bg-warmGray-800 rounded-lg p-6"
        >
          <h2 className="text-xl font-bold text-teal-400 mb-4 pb-3 border-b border-warmGray-700">
            Locations
          </h2>

          <div className="space-y-4">
            {data.worldLocations.map((location, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * index }}
                className="bg-warmGray-700 p-4 rounded-lg"
              >
                <h3 className="text-white font-medium text-lg mb-2">{location.name}</h3>
                <p className="text-warmGray-400 text-sm mb-4">{location.description}.</p>

                <div className="space-y-2">

                  {/* <div>
                    <h4 className="text-sm font-medium text-teal-400 mb-2">Actions</h4>
                    <div className="flex flex-wrap gap-2">
                      {location.action_options.map((action, actionIndex) => (
                        <span
                          key={actionIndex}
                          className="px-3 py-1 text-sm bg-orange-900/50 text-orange-200 rounded-full"
                        >
                          {action}
                        </span>
                      ))}
                    </div>
                  </div> */}

                  {location.subLocations && (
                    <div>
                      <h4 className="text-sm font-medium text-teal-400 mb-2">Sub-locations</h4>
                      <div className="space-y-2">
                        {location.subLocations.map((subLoc, subIndex) => (
                          <motion.div
                            key={subIndex}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2 + (0.1 * subIndex) }}
                            className="bg-warmGray-800 p-3 rounded-lg"
                          >
                            <div className="text-white font-medium">{subLoc.name}</div>
                            <p className="text-sm text-warmGray-400 mt-1">{subLoc.description}</p>
                            <span className="inline-block mt-2 px-2 py-1 text-xs bg-teal-900/50 text-teal-200 rounded">
                              {subLoc.action_option}
                            </span>
                          </motion.div>
                        ))}
                      </div>
                    </div>)}

                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      )}

      {/* // Quest tab component content */}
      {activeTab === 'quests' && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-warmGray-800 rounded-lg overflow-hidden"
        >
          {/* Header */}
          <div className="p-4 border-b border-warmGray-700">
            <h2 className="text-xl font-semibold text-teal-400">Quests</h2>
          </div>

          {/* Quest List */}
          <div className="p-4 space-y-2">
            {data.quests.map((quest, index) => (
              <motion.div
                key={quest.name}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-warmGray-700/50 rounded-lg overflow-hidden"
              >
                {/* Quest Header */}
                <div className="p-4 bg-warmGray-700/50">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium text-white">{quest.name}</h3>
                      <p className="text-sm text-warmGray-400">
                        Faction: {quest.factionInvolvement}
                      </p>
                    </div>
                    <span className="px-3 py-1 text-sm bg-orange-900/50 text-orange-200 rounded-full">
                      {quest.status}
                    </span>
                  </div>
                </div>

                {/* Quest Details */}
                <div className="p-4 space-y-2">
                  {/* Requirements */}
                  <div>
                    <h4 className="text-sm font-medium text-teal-400 mb-2">Requirements</h4>
                    <ul className="space-y-1">
                      {quest.requirements.map((req, reqIndex) => (
                        <li
                          key={reqIndex}
                          className="text-sm text-warmGray-400 flex items-center gap-2"
                        >
                          <span className="w-1.5 h-1.5 bg-teal-400 rounded-full"></span>
                          {req.requirement} {req.complete ? "[Completed]" : ""}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Rewards */}
                  <div>
                    <h4 className="text-sm font-medium text-teal-400 mb-2">Potential Rewards</h4>
                    <ul className="space-y-1">
                      {quest.potentialReward.map((reward, rewardIndex) => (
                        <li
                          key={rewardIndex}
                          className="text-sm text-warmGray-400 flex items-center gap-2"
                        >
                          <span className="w-1.5 h-1.5 bg-orange-400 rounded-full"></span>
                          {reward}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Moral Dilemmas */}
                  {quest.moralDilemmas && quest.moralDilemmas.length > 0 && (
                    <div>
                      <h4 className="text-sm font-medium text-teal-400 mb-2">Moral Dilemmas</h4>
                      <div className="space-y-3">
                        {quest.moralDilemmas.map((dilemma, dilemmaIndex) => (
                          <motion.div
                            key={dilemmaIndex}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2 + (dilemmaIndex * 0.1) }}
                            className="bg-warmGray-800/50 rounded-lg p-3"
                          >
                            <p className="text-sm text-warmGray-300">{dilemma.description}</p>
                            <p className="text-xs text-orange-300 mt-2">
                              Consequences: {dilemma.consequences}
                            </p>
                          </motion.div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Additional Info */}
                  <div className="flex items-center justify-between pt-2 border-t border-warmGray-600/50">
                    <span className="text-xs text-warmGray-400">
                      {quest.timeSensitive ? 'Time Sensitive' : 'No Time Limit'}
                    </span>
                    <span className="text-xs text-teal-400">
                      Faction Standing Required: {quest.factionInvolvement}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}

            {/* Empty State */}
            {data.quests.length === 0 && (
              <div className="text-center py-8">
                <p className="text-warmGray-400">No quests available</p>
              </div>
            )}
          </div>

          {/* Quest Summary Footer */}
          {/* <div className="bg-warmGray-700/30 p-4 mt-4">
          <div className="flex justify-between items-center">
            <span className="text-sm text-warmGray-400">
              Active Quests: {data.quests.length}
            </span>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-teal-600 hover:bg-teal-500 text-white rounded-lg 
                   text-sm transition-colors duration-200"
            >
              Find More Quests
            </motion.button>
          </div>
        </div> */}



        </motion.div>
      )}
    </div>}
  </div>
  )
}


export default GameTabs;