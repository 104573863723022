import React, { useEffect, useState } from 'react';
import Atlas from './components/Atlas';
import MyriadRealms from './components/MyriadRealms';
import ChatOpenAI from './components/ChatOpenAI';

import * as Zustand from './Zustand';
import EncryptButton from './components/EncryptButton';
import TextToSpeechPlayer from './components/TextToSpeechPlayer';

import Login from './Login';
import Logout from './Logout';
import SignIn from './SignIn';

import logo from './assets/logo.png';
import { motion } from "motion/react";
import { gapi } from 'gapi-script';

import firebase from 'firebase/app';
import 'firebase/auth';
import GameTabs from './GameTabs';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const clientId = process.env.GOOGLE_SSO_CLIENTID

const App = () => {
  const [selectedNode, setSelectedNode] = useState(null);
  const [narrator, setNarrator] = useState(true)
  const { initialised, setInitialised, audioText, setUser, setToken, user, token, setGameState, gameState: data } = Zustand.GlobalStore();

  const handleLogin = (token) => {
    setToken(token);
  };

  const handleSave = async () => {
    const data = { id: '123', name: 'Sample Data' };
    // const result = await saveData(token, data);
    // console.log('Save Result:', result);
  };

  const handleFetch = async () => {
    // const result = await fetchData(token, '123');
    // console.log('Fetch Result:', result);
  };

  const handleList = async () => {
    // const result = await listData(token);
    // console.log('List Result:', result);
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      })
    }

    gapi.load('client:auth2', start)
  }, [])

  // var accessToken = gapi.auth.getToken().access_token


  return (
    <div className='h-full w-full overflow-hidden'>

      <div className='h-full w-full fixed'>
        <Atlas onSelectNode={setSelectedNode} />
      </div>


      <motion.h1
        className='text-xl w-full py-5 pl-6 absolute tracking-tight  text-warmGray-50 capitalize flex items-center'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transitions={{ duration: 0.8, delay: 1, ease: "cubicBezier" }}
        onClick={() => setInitialised(true)}
      >
        <img className="h-[40px] pr-4" src={logo} alt="Logo" />Taleweaver: Myriad Realms
      </motion.h1>


      <div className='fixed right-0 top-0 h-full opacity-80 hover:opacity-95 bg-opacity-[0.97] bg-warmGray-800 w-[25%]  drop-shadow-sm border-r-warmGray-600 transition-opacity' >


        {/* {!initialised && <div>
          <Login onSuccess={res=> setUser(res)}/>
          <Logout onSuccess={res=> setUser(null)}/>
        </div>} */}

        {/* {!token ? (
          <SignIn onLogin={handleLogin} />
        ) : (
          <>
            <button onClick={handleSave}>Save Data</button>
            <button onClick={handleFetch}>Fetch Data</button>
            <button onClick={handleList}>List Data</button>
          </>
        )} */}


        {/* Mythweaver, Starweaver */}

        {initialised && <div className="h-full">

          {!narrator && <ChatOpenAI selectedNode={selectedNode} />}
          {narrator && <MyriadRealms selectedNode={selectedNode} />}
          {/* <TextToSpeechPlayer /> */}

        </div>}

        {!initialised && <div className='bg-slate-100 my-[48vh] mx-6 py-2 rounded-sm cursor-pointer flex self-center justify-center' onClick={() => setInitialised(true)}>Start</div>}

        {/* <h1 className='font-normal text-xs p-4 tracking-tight z-10 text-warmGray-50 bottom-0'
          onClick={() => !initialised ? setNarrator(!narrator) : null}
        >{narrator ? "CLAUDE SONNET." : "GPT-4O."}</h1> */}

      </div>



      {initialised && <div className='h-[40vh] bg-transparent top-[100px]'>
        <GameTabs data={data} />
      </div>}

      <div className="space-y-4">
        {/* XP Bar */}
        <motion.div
          initial={{ x: -20 }}
          animate={{ x: 0 }}
          className="space-y-2"
        >
          <div className="flex justify-between text-sm text-warmGray-400">
            <span>Level {data.player.lvl}</span>
            <span>{data.player.xp}/{data.player.nextLevelXp} XP</span>
          </div>
          <div className="h-1 bg-warmGray-700 rounded-full overflow-hidden">
            <div
              className="h-full bg-teal-500 transition-all duration-300"
              style={{ width: `${(data.player.xp / data.player.nextLevelXp) * 100}%` }}
            />
          </div>
        </motion.div>

        {/* Energy Bar */}
        <motion.div
          initial={{ x: -20 }}
          animate={{ x: 0 }}
          className="space-y-1"
        >
          <div className="flex justify-between text-xsm text-warmGray-400">
            {/* <span>Level {data.player.lvl}</span> */}
            <span />
            <span>{data.player.energy}/100 Energy</span>
          </div>

          <div className="h-1 bg-warmGray-700 rounded-full overflow-hidden">
            <div
              className="h-full bg-yellow-500 transition-all duration-300"
              style={{ width: `${(data.player.energy / 100) * 100}%` }}
            />
          </div>
        </motion.div>
      </div>

      <div className='fixed bottom-0 font-normal text-xs p-4 tracking-tight z-100 text-warmGray-50'>&copy; 2024 DATA CORRIDOR LIMITED.</div>
    </div>
  );
};

export default App;
